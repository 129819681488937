<template>
  <div v-if="insuranceOffer">
    <div class="flex justify-between">
      <div class="flex flex-col gap-4 md:flex-row md:items-center md:gap-8">
        <div>
          <RevIllustration
            :alt="i18n(translations.illustrationAlternative)"
            :height="21"
            src="/img/insurances/backUpLogo.svg"
            :width="96"
          />
        </div>
        <div class="text-static-default-hi body-1-bold">
          {{ i18n(translations.title) }}
        </div>
      </div>
      <RevButtonTiny
        class="hidden md:block"
        variant="secondaryDestructive"
        @click="$emit('remove')"
      >
        {{ i18n(translations.remove) }}
      </RevButtonTiny>
    </div>

    <div class="text-static-default-hi body-1-bold">
      {{ i18n.price(insuranceOffer.price) }}
    </div>

    <div class="flex items-end justify-between">
      <RevLink @click="openModal(MODAL_NAMES.BATTERY_REPLACEMENT_BENEFITS)">
        <div class="text-static-default-low body-2-link">
          {{ i18n(translations.link) }}
        </div>
      </RevLink>

      <RevButtonTiny
        class="md:hidden"
        variant="secondaryDestructive"
        @click="$emit('remove')"
      >
        {{ i18n(translations.remove) }}
      </RevButtonTiny>
    </div>

    <RevCheckbox
      id="battery-replacement-compliancy"
      v-model="agreementChecked"
      class="mt-12 md:mt-8"
    >
      <template #label>
        <Terms :documents="insuranceOffer.compliancy.documents" />
      </template>
    </RevCheckbox>

    <RevDialog
      :closeButtonLabel="i18n(translations.close)"
      :name="MODAL_NAMES.BATTERY_REPLACEMENT_BENEFITS"
      :title="i18n(translations.modalTitle)"
    >
      <template #body>
        <div class="space-y-16">
          <BatteryReplacementIllustration />

          <div class="space-y-8">
            <div
              v-for="benefit in insuranceOffer.benefits.long"
              :key="benefit"
              class="flex items-center gap-4"
            >
              <IconCheckInCircle class="text-static-success-hi h-16 w-16" />
              <div class="text-static-default-low body-2">{{ benefit }}</div>
            </div>
          </div>
        </div>
      </template>
    </RevDialog>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { type CheckoutInsuranceOffer } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonTiny } from '@ds/components/ButtonTiny'
import { RevCheckbox } from '@ds/components/Checkbox'
import { RevDialog } from '@ds/components/Dialog'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import Terms from '~/scopes/checkout/components/Terms/Terms.vue'
import { MODAL_NAMES } from '~/scopes/product/constants'

import BatteryReplacementIllustration from '../BatteryReplacementIllustration/BatteryReplacementIllustration.vue'

import translations from './BatteryReplacementBundle.translations'

const props = defineProps<{
  insuranceOffer: CheckoutInsuranceOffer | null
}>()

defineEmits(['remove'])

const i18n = useI18n()

const agreementChecked = ref(
  props.insuranceOffer?.compliancy.isUserAgreementAccepted,
)
</script>
